import { memo } from "react";
import styled from "styled-components"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
`

const ScreenContainerComponent = ({ id, children }: { id?: string, children?: React.ReactNode }) => {

    return (

        <Container id={id}>

            {children}

        </Container>

    )
}

const ScreenContainer = memo(ScreenContainerComponent);

export default ScreenContainer;