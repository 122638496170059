import styled, { css } from "styled-components";
import { device } from "../styles/stylesConfig";
import { useHistory } from "react-router-dom";
import { startLoading } from "../actions/ui";
import { useDispatch, useSelector } from "react-redux";
import { useLogEvent } from "../hooks/useLogEvent";
import { globalTexts, introTexts } from "../constants/TextConstants";
import { handleClickWantMyTable } from "../helpers/TicektsHelper";


const Container = styled.div`
    width: 100%;
    height: 25vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: -7vh;

    & > button:nth-of-type(1) {
        background-color: #D90B0B;
        font-size: 30px;
        line-height: 45px;

        @media ${device.lg} {
            font-size: 20px;
            line-height: 25px;
        }

    }

    & > button:nth-of-type(2) {
        background-color: #b1b1b1;
        margin: 0px 15px;
        padding: 15px 25px;
        font-size: 20px;
    
        &:hover {
            transform: scale(1.05);
        }

        @media ${device.lg} {
            font-size: 16px;
            line-height: 25px;
        }

        @media ${device.md} {
            font-size: 14px;
            padding: 12px 20px;
            line-height: 20px;
        }

        @media ${device.sm} {
            font-size: 10px;
            line-height: 16px;
            padding: 10px 15px;
        }

        @media ${device.sm} {
            margin: 0px 5px;
        }


    }
`

// const ButtonsContainer = styled.div`
//     display: grid;
//     grid-template-columns: repeat(2, 1fr);
//     justify-content: center;
//     align-items: center;

//     @media ${device.lg} {
//         grid-template-columns: repeat(2, 1fr);
//         margin: 0;
//     }

//     & > button:nth-of-type(1) {
//         background-color: #DAA217;
//         margin: 0px 15px;

//         @media ${device.sm} {
//             margin: 0px 5px;
//         }
//     }

//     & > button:nth-of-type(2) {
//         background-color: #FFFFFF;
//         margin: 0px 15px;

//         @media ${device.sm} {
//             margin: 0px 5px;
//         }

//     }

//     & > button:nth-of-type(3) {
//         background-color: #b1b1b1;
//         margin: 0px 15px;

//         @media ${device.sm} {
//             margin: 0px 5px;
//         }
//     }
// `


const ButtonsContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    justify-content: center;
    align-items: center;

    /* & > button:nth-of-type(1) {
        background-color: #DAA217;
        margin: 0px 15px;

        @media ${device.sm} {
            margin: 0px 5px;
        }
    } */

    & > button:nth-of-type(1) {
        background-color: #DAA217;
        margin: 0px 15px;

        @media ${device.sm} {
            margin: 0px 5px;
        }

    }

    & > button:nth-of-type(2) {
        background-color: #b1b1b1;
        margin: 0px 15px;

        @media ${device.sm} {
            margin: 0px 5px;
        }
    }
`

const SocialButton = styled.button<{ dimension?: 'mobile' | 'desktop' }>`
    padding: 15px 25px;
    position: relative;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 20px;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 30px;
    cursor: pointer;
    color: black;
    transition: all .3s ease;
    z-index: 3;
    
    &:hover {
        transform: scale(1.05);
    }

    @media ${device.lg} {
        font-size: 16px;
        line-height: 25px;
    }

    @media ${device.md} {
        font-size: 14px;
        padding: 12px 20px;
        line-height: 20px;
    }

    @media ${device.sm} {
        font-size: 10px;
        line-height: 16px;
        padding: 10px 15px;
    }

`

// const SocialButton = styled.button<{ dimension?: 'mobile' | 'desktop' }>`
//     padding: 15px 25px;
//     position: relative;
//     background-color: white;
//     display: flex;
//     justify-content: center;
//     align-items: center;
//     border-radius: 10px;
//     font-size: 20px;
//     font-weight: bold;
//     text-transform: uppercase;
//     line-height: 30px;
//     cursor: pointer;
//     color: black;
//     transition: all .3s ease;
//     z-index: 3;

//     &:hover {
//         transform: scale(1.05);
//     }

//     @media ${device.lg} {
//         font-size: 16px;
//         line-height: 25px;
//     }

//     @media ${device.md} {
//         font-size: 14px;
//         padding: 12px 20px;
//         line-height: 20px;
//     }

//     @media ${device.sm} {
//         font-size: 10px;
//         line-height: 16px;
//         padding: 10px 15px;
//     }

//     ${({ dimension }) => dimension === 'desktop' && css`
//         display: flex;

//         @media ${device.lg} {
//             display: none;
//         }

//     `}

//     ${({ dimension }) => dimension === 'mobile' && css`
//         display: none;


//         @media ${device.lg} {
//             display: flex;
//             font-size: 28px;
//             line-height: 35px;
//         }

//         @media ${device.md} {
//             font-size: 24px;
//             padding: 12px 20px;
//             line-height: 30px;
//         }

//         @media ${device.sm} {
//             font-size: 20px;
//             line-height: 24px;
//             padding: 10px 15px;
//         }
//     `}
// `

const ActionMenuComponent = () => {

    const { loading } = useSelector((state: any) => state.ui);

    const { handleLogEvent } = useLogEvent();

    const dispatch: any = useDispatch();

    const history: any = useHistory();

    return (

        <Container className={!loading ? 'utils__fade-in-fast-top' : ''}>
            {/* 
            <SocialButton
                onClick={(e) => {
                    window.open(introTexts.wantMyTicketAndMoreInfoIntroduction.ticketLink, "_blank");

                    handleLogEvent(`click_boton_entrada_${introTexts.wantMyTicketAndMoreInfoIntroduction.ticketEvent}`)
                }}
                style={{ backgroundColor: '#D90B0B', marginBottom: 10 }}
            >Quiero<br />mi entrada</SocialButton> */}

            <ButtonsContainer>

                {/* <SocialButton
                    onClick={async () => {

                        dispatch(startLoading());

                        await history.push(introTexts.wantMyTicketAndMoreInfoIntroduction.goTo);

                        handleLogEvent(`click_boton_mas_informacion_${introTexts.wantMyTicketAndMoreInfoIntroduction.ticketEvent}`)

                    }}
                >
                    Información
                </SocialButton> */}

                <SocialButton
                    onClick={() => {

                        handleClickWantMyTable(
                            introTexts.wantMyTicketAndMoreInfoIntroduction.tablesLink,
                            introTexts.wantMyTicketAndMoreInfoIntroduction.ticketEvent,
                            handleLogEvent
                        )

                    }}
                >Quiero mi mesa</SocialButton>

                <SocialButton
                    dimension="desktop"
                    onClick={(e) => {

                        window.open(globalTexts.picturesLink, "_blank");

                        handleLogEvent(`click_boton_fotos`)
                    }}
                >Fotos</SocialButton>


            </ButtonsContainer>

            {/* <SocialButton
                dimension="mobile"
                onClick={(e) => {

                    window.open(globalTexts.picturesLink, "_blank");

                    handleLogEvent(`click_boton_fotos`)
                }}
                style={{ backgroundColor: '#b1b1b1', marginTop: 10 }}

            >Fotos</SocialButton> */}


        </Container>
    )
}

const ActionMenu = ActionMenuComponent;

export default ActionMenu;